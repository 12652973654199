<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <b-tabs v-if="mediaTypesOptions" content-class="">
      <b-tab
        v-for="(option, index) in mediaTypesOptions"
        :key="index"
        @click="filterBy(option.itemToken)"
        :title-item-class="'custom-tab-inner-li tab-container-media'"
      >
        <template #title>
          <span v-b-popover.hover.bottomright="`${option.itemName}`">
            <b-badge variant="danger" class="count-notifications">{{
              option.itemTotalInnerCount
            }}</b-badge>
            <img
              :src="baseUrl + option.itemImagePath"
              :onerror="`this.src='${defaultImg}'`"
              class="icon-tab"
            />
            <span>{{ option.itemName }}</span>
          </span>
        </template>
      </b-tab>
    </b-tabs>
    <div
      v-if="employeesFoldersMedias.employeesFoldersMediasData"
      class="custom-tab-inner-content"
    >
      <div class="row">
        <div
          v-for="(
            media, index
          ) in employeesFoldersMedias.employeesFoldersMediasData"
          :key="index"
          :class="isDoc"
        >
          <FolderMediaCard
            v-if="
              media.mediaTypeToken == imageToken ||
              media.mediaTypeToken == videoToken
            "
            :folderMediaData="media"
            v-on:setFolderMediaData="setFolderMediaData($event)"
          />
          <div v-if="media.mediaTypeToken == audioToken">
            <table class="my-table">
              <tr>
                <td>
                  <CustomAudioPlayer
                    :id="media.folderMediaToken"
                    :name="media.folderMediaNameCurrent"
                    :artist="media.folderMediaCode"
                    :audioSrc="media.folderMediaPath"
                    :image="defualtImage"
                  />
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'EmployeesFoldersMediaEdit',
                      params: {
                        employeeToken: media.employeeToken,
                        folderMediaToken: media.folderMediaToken,
                      },
                    }"
                    :title="$t('edit')"
                  >
                    <img src="@/assets/images/pencil.svg" class="icon-lg" />
                  </router-link>
                </td>
                <td>
                  <button
                    v-b-modal.FolderMediaDelete
                    class="btn p-0"
                    :title="$t('delete')"
                    @click="setFolderMediaData(media)"
                  >
                    <img src="@/assets/images/trash.svg" class="icon-lg" />
                  </button>
                </td>
              </tr>
            </table>
          </div>
          <div
            v-if="
              media.mediaTypeToken == exlToken ||
              media.mediaTypeToken == wordToken ||
              media.mediaTypeToken == powToken
            "
            class="row container-collapse-with-btns"
          >
            <b-button
              v-b-toggle="`item${index}`"
              class="btn btn-lg btn-collapse collapse-data"
            >
              <div class="row collapse-title">
                <div class="col-md-12 col-lg-12">
                  {{ media.folderMediaNameCurrent }}
                </div>
              </div>
              <div class="collapse-icon">
                <i class="fa fa-angle-down" aria-hidden="true"></i>
              </div>
            </b-button>
            <div class="collapse-actions btn-collapse">
              <div class="">
                <router-link
                  :to="{
                    name: 'EmployeesFoldersMediaEdit',
                    params: {
                      employeeToken: media.employeeToken,
                      folderMediaToken: media.folderMediaToken,
                    },
                  }"
                  :title="$t('edit')"
                >
                  <img src="@/assets/images/pencil.svg" class="icon-lg" />
                </router-link>
              </div>
              <div class="">
                <button
                  v-b-modal.FolderMediaDelete
                  class="btn p-0"
                  :title="$t('delete')"
                  @click="setFolderMediaData(media)"
                >
                  <img src="@/assets/images/trash.svg" class="icon-lg" />
                </button>
              </div>
            </div>
            <b-collapse :id="`item${index}`" class="col-md-12 col-lg-12">
              <VueDocPreview
                :url="baseUrl + media.folderMediaPath"
                :type="`office`"
              />
            </b-collapse>
          </div>
          <div
            v-if="media.mediaTypeToken == pdfToken"
            class="row container-collapse-with-btns"
          >
            <b-button
              v-b-toggle="`item${index}`"
              class="btn btn-lg btn-collapse collapse-data"
            >
              <div class="row collapse-title">
                <div class="col-md-12 col-lg-12">
                  {{ media.folderMediaNameCurrent }}
                </div>
              </div>
              <div class="collapse-icon">
                <i class="fa fa-angle-down" aria-hidden="true"></i>
              </div>
            </b-button>
            <div class="collapse-actions btn-collapse">
              <div class="">
                <router-link
                  :to="{
                    name: 'EmployeesFoldersMediaEdit',
                    params: {
                      employeeToken: media.employeeToken,
                      folderMediaToken: media.folderMediaToken,
                    },
                  }"
                  :title="$t('edit')"
                >
                  <img src="@/assets/images/pencil.svg" class="icon-lg" />
                </router-link>
              </div>
              <div class="">
                <button
                  v-b-modal.FolderMediaDelete
                  class="btn p-0"
                  :title="$t('delete')"
                  @click="setFolderMediaData(media)"
                >
                  <img src="@/assets/images/trash.svg" class="icon-lg" />
                </button>
              </div>
            </div>
            <b-collapse :id="`item${index}`" class="col-md-12 col-lg-12">
              <vue-pdf-app
                style="height: 100vh"
                :pdf="baseUrl + media.folderMediaPath"
                theme="dark"
              ></vue-pdf-app>
            </b-collapse>
          </div>
        </div>
      </div>

      <Pagination
        v-if="!isLoading"
        :paginationData="employeesFoldersMedias.pagination"
        v-on:changePage="changePage"
      />
      <NewsImgZoom :src="src" />
      <VideoShowModal :src="src" />
      <FolderMediaDelete
        :folderMediaData="employeesFoldersMedias.employeesFoldersMedia"
        v-on:refresh="getAllEmployeesFoldersMedias()"
      />
    </div>
    <ExceptionWithImg v-else :msg="exceptionMsg" />

    <FolderMediaFloatBtns
      :theFilterData="employeesFoldersMedias.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import NewsImgZoom from "@/components/news/NewsImgZoom.vue";
import VideoShowModal from "@/components/general/VideoShowModal.vue";
import VueDocPreview from "vue-doc-preview";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
import {
  BASE_URL,
  STATUS,
  NEWS_MEDIA_TYPE_TOKEN_IMAGE,
  NEWS_MEDIA_TYPE_TOKEN_VIDEO,
  NEWS_MEDIA_TYPE_TOKEN_AUDIO,
  NEWS_MEDIA_TYPE_TOKEN_PDF,
  NEWS_MEDIA_TYPE_TOKEN_EXCEL,
  NEWS_MEDIA_TYPE_TOKEN_WORD,
  NEWS_MEDIA_TYPE_TOKEN_POWER_POINT,
} from "@/utils/constants";
import FolderMediaCard from "@/components/employees/employeesFoldersMedias/FolderMediaCard.vue";
import FolderMediaDelete from "@/components/employees/employeesFoldersMedias/FolderMediaDelete.vue";
import FolderMediaFloatBtns from "@/components/employees/employeesFoldersMedias/FolderMediaFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import CustomAudioPlayer from "@/components/general/CustomAudioPlayer.vue";
import DefualtImage from "@/assets/images/media.svg";
import DEFAULT_IMG from "@/assets/images/media.svg";
import EmployeesFoldersMedias from "@/models/employees/employeesFoldersMedias/EmployeesFoldersMedias";

export default {
  name: "EmployeesFoldersMedias",
  components: {
    PreLoader,
    ExceptionWithImg,
    FolderMediaCard,
    FolderMediaDelete,
    FolderMediaFloatBtns,
    Pagination,
    CustomAudioPlayer,
    NewsImgZoom,
    VueDocPreview,
    VideoShowModal,
    VuePdfApp,
  },
  props: ["employeeFolderToken"],
  data() {
    return {
      mediaTypesOptions: null,
      isLoading: true,
      isNoContent: false,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      employeesFoldersMedias: new EmployeesFoldersMedias(),
      defualtImage: DefualtImage,
      imageToken: NEWS_MEDIA_TYPE_TOKEN_IMAGE,
      videoToken: NEWS_MEDIA_TYPE_TOKEN_VIDEO,
      audioToken: NEWS_MEDIA_TYPE_TOKEN_AUDIO,
      pdfToken: NEWS_MEDIA_TYPE_TOKEN_PDF,
      exlToken: NEWS_MEDIA_TYPE_TOKEN_EXCEL,
      wordToken: NEWS_MEDIA_TYPE_TOKEN_WORD,
      powToken: NEWS_MEDIA_TYPE_TOKEN_POWER_POINT,
      baseUrl: BASE_URL,
      defaultImg: DEFAULT_IMG,
      src: "",
      currentTab: "",
    };
  },
  methods: {
    setFolderMediaData(data) {
      this.employeesFoldersMedias.employeesFoldersMedia.fillData(data);
      if (this.employeesFoldersMedias.employeesFoldersMedia.folderMediaPath) {
        this.src =
          BASE_URL +
          this.employeesFoldersMedias.employeesFoldersMedia.folderMediaPath;
      } else {
        this.src = this.defaultImg;
      }
    },
    filterBy(mediaTypeToken) {
      this.currentTab = mediaTypeToken;
      this.employeesFoldersMedias.filterData.mediaTypeToken = mediaTypeToken;
      this.getAllEmployeesFoldersMedias();
    },
    changePage(page) {
      this.employeesFoldersMedias.pagination.selfPage = page;
      this.getAllEmployeesFoldersMedias();
    },
    search(data) {
      this.employeesFoldersMedias.filterData.fillData(data);
      this.employeesFoldersMedias.employeesFoldersMediasData = null;
      this.getDialogOfMediaTypes();
      this.getAllEmployeesFoldersMedias();
    },
    async getStatisticsEmployeeFoldersMedias() {
      this.isLoading = true;
      try {
        const response =
          await this.employeesFoldersMedias.employeesFoldersMedia.getStatisticsEmployeeFoldersMedias(
            this.language,
            this.userAuthorizeToken,
            this.employeesFoldersMedias.filterData
          );

        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.mediaTypesOptions = response.data.generalStaticList;
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.mediaTypesOptions = null;
          this.isNoContent = true;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.mediaTypesOptions = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.mediaTypesOptions = null;
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.mediaTypesOptions = null;
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
    async getAllEmployeesFoldersMedias() {
      this.isLoading = true;
      try {
        const response =
          await this.employeesFoldersMedias.employeesFoldersMedia.getAllEmployeesFoldersMedias(
            this.language,
            this.userAuthorizeToken,
            this.employeesFoldersMedias.pagination,
            this.employeesFoldersMedias.filterData
          );

        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.employeesFoldersMedias.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.employeesFoldersMedias.employeesFoldersMediasData = null;
          this.isNoContent = true;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.employeesFoldersMedias.employeesFoldersMediasData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.employeesFoldersMedias.employeesFoldersMediasData = null;
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.employeesFoldersMedias.employeesFoldersMediasData = null;
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
    setFilter() {
      this.employeesFoldersMedias.filterData.employeeFolderToken = this
        .employeeFolderToken
        ? this.employeeFolderToken
        : this.$store.getters.employeeFolderToken;
      this.employeesFoldersMedias.filterData.mediaTypeToken = this
        .mediaTypeToken
        ? this.mediaTypeToken
        : "";
    },
  },
  computed: {
    isDoc: function () {
      if (this.mediaTypesOptions) {
        if (
          this.currentTab == this.mediaTypesOptions[0].itemToken ||
          this.currentTab == this.mediaTypesOptions[1].itemToken ||
          this.currentTab == this.mediaTypesOptions[2].itemToken
        ) {
          return "col-md-6 col-lg-6";
        } else {
          return "col-md-12";
        }
      } else {
        return "col-md-12";
      }
    },
  },
  async created() {
    if (this.employeeFolderToken) {
      this.$store.dispatch(
        "updateEmployeeFolderToken",
        this.employeeFolderToken
      );
    }
    this.setFilter();
    await this.getStatisticsEmployeeFoldersMedias();
    this.currentTab = this.mediaTypesOptions[0].itemToken;
    this.employeesFoldersMedias.filterData.mediaTypeToken = this.currentTab;
    this.getAllEmployeesFoldersMedias();
  },
};
</script>

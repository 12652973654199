<template>
  <aplayer :audio="audio" :id="id" />
</template>

<script>
import { BASE_URL } from "@/utils/constants";
export default {
  name: "CustomAudioPlayer",
  data() {
    return {
      audio: {
        name: this.name,
        artist: this.artist,
        url: BASE_URL + this.audioSrc,
        cover: this.image,
      },
    };
  },
  methods: {},
  props: {
    name: {
      type: String,
      default: "",
    },
    artist: {
      type: String,
      default: "",
    },
    audioSrc: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "aplyer",
    },
  },
  watch: {},
  async created() {},
};
</script>

<style lang="scss" scoped></style>
